import { AptAddressType } from '../../common/enums/apttus/apt-address-type';
import { AptBillType } from '../../common/enums/apttus/apt-bill-type';
import { AptCommodityType } from '../../common/enums/apttus/apt-commodity-typeof-sale';
import { HoursBundle } from '../../common/enums/apttus/apt-hours-bundle';
import { AptLineStatus } from '../../common/enums/apttus/apt-line-status';
import { AptContactRole } from '../../common/enums/apttus/apt-mb-representative';
import { AptPaymentFrequency } from '../../common/enums/apttus/apt-payment-frequency';
import { AptPaymentInstrument } from '../../common/enums/apttus/apt-payment-instrument';
import { AptPaymentType } from '../../common/enums/apttus/apt-payment-type';
import { PowerPhase } from '../../common/enums/apttus/apt-power-phase';
import { AptPowerVoltage } from '../../common/enums/apttus/apt-power-voltage';
import { AptProductFamily } from '../../common/enums/apttus/apt-product-family';
import { AptProductStatus } from '../../common/enums/apttus/apt-product-status';
import { AptProductType } from '../../common/enums/apttus/apt-product-type';
import { NetaProductClass } from '../../common/enums/apttus/neta-product-class';
import { ClasseDiPrelievo } from '../../common/enums/shared/classe-di-prelievo';
import { DestinationUse, SupplyUse } from '../../common/enums/shared/destination-use.enum';
import { GasMeterClass } from '../../common/enums/shared/gas-meter-class';
import { PdrType } from '../../common/enums/shared/pdr-type';
import { SubProductType } from '../../common/enums/shared/sub-product-type';
import { GasTypeOfUse, PowerTypeOfUse, TypeOfUse } from '../../common/enums/shared/type-of-usage.type';
import { VirtualAgentBackOffice } from '../../common/models/user/agent';
import { HistorizedAgencyBranch } from '../../common/models/user/historized-agency-branch';
import { IntestarioConto } from '../../modules/common/order-entry/models/form-bonifico';
import { FotoDocumenti, SelfCertificationData } from '../../modules/common/order-entry/models/foto-documenti';
import { Ese } from '../../modules/common/order-entry/steps/appointment/models/ese';
import { Convention } from '../../modules/common/partnership/models/conventions';
import { PartnerShipInfo } from '../../modules/common/partnership/models/partnership-info';
import { TaxDeduction } from '../../modules/common/partnership/models/taxDeductions';
import { MotivoRevoca } from '../../modules/domiciliation/init-domiciliation/init-domiciliation.component';
import { OcrData } from '../../modules/switch-in/order-entry/models/ocr-data';
import { QuestionIvassState } from '../../modules/switch-in/products/models/ivass-question';
import { AtecoClassification6409 } from '../../modules/switch-in/technical-changes/enums/ateco_classification';
import { AgencyBranchForSubsidiary } from '../../modules/switch-in/technical-changes/models/agency-branch-for-subsidiary';
import { TransferModelResponse } from '../../modules/transfer/transfer-model/models/transfer-model-response';
import { FlowType } from './flow-type';
import {
    CommodityEstateCardinality,
    ContractCode,
    CreditCheckStatus,
    DomandePrivacy,
    FatturazioneElettronica,
    Firma,
    FornituraEsistente,
    Incident,
    InvalidCf,
    Mp3Info,
    PlicoPdfInfo,
    PropertyTypes,
    QuoteStateModel,
    SalesProcessPartNumber,
    TitolaritaImmobile,
    VerificaContatto,
} from './order-entry-state';
import { ExistingMastershipType } from './user-state';

/*
  _                            _              _       _
 (_)_ __ ___  _ __   ___  _ __| |_ __ _ _ __ | |_    | |
 | | '_ ` _ \| '_ \ / _ \| '__| __/ _` | '_ \| __|   | |
 | | | | | | | |_) | (_) | |  | || (_| | | | | |_    |_|
 |_|_| |_| |_| .__/ \___/|_|   \__\__,_|_| |_|\__|   (_)
             |_|

 QUALSIASI, ripeto QUALSIASI aggiunta/modifica di nuove proprietà/oggetto
 dello state OrdeEntryState deve essere concordata con: felice.lombardi@avanade.com / m.ricupero@avanade.com
 DIVERSAMENTE, VERRANNO RIGETTATE TUTTE LE PR CONTENENTI QUESTO FILE.

*/
export class OrderEntryState_v2 {
    version: 2;
    flowType: FlowType;
    // cartInfo: CartInfo; // da gestire con nuova CP
    cartId: string;
    products: Product[] = [];
    /**Dati appuntamento Attivazione/Cessazione */
    appointment?: OrderAppointment;
    // Spostare nell'UserStateV2
    contact: ContactV2;
    // communicationAddress: StateAddress; // indirizzo di comunicazione e fatturazione

    convention?: Convention; //La convenzione viene gestita a livello di cart/ordine
    taxDeduction?: TaxDeduction; //La detrazione fiscale viene gestita a livello di cart/ordine per filtrare il catalogo
    creditAssignment?: boolean; // Cessione del credito
    // #region DA RIVEDERE IN FUTURO
    fotoDocumenti: FotoDocumenti;
    firma?: Firma;
    mp3Info?: Mp3Info;
    uploadPdfPlicoInfo?: PlicoPdfInfo;
    numeroPlico: ContractCode;
    tipoEsecuzione: {
        isRataFissaDatesEquals?: boolean;
        dataStandardCommodity?: Date;
        dataAnticipataCommodity?: Date;
        isRataFissaCP?: boolean;
    };
    fornitureEsistenti: FornituraEsistente[];
    dagCode?: string;
    // fornitureAttiveSelezionate: FornituraEsistente[];

    quoteStateModel?: QuoteStateModel;
    verificaContatto: VerificaContatto;
    agencyBranchForMonitoring?: string;
    branchAgenziaAgente?: HistorizedAgencyBranch;
    plicoUrl: string;
    anagraficaMb: DatiAnagraficiMBV2; // da spostare in user state
    ocrData: OcrData;
    fatturazioneElettronica: FatturazioneElettronica;
    invalidCf?: InvalidCf;
    creditCheckStatus?: CreditCheckStatus;
    deferredSaleEnabled: boolean;
    responsabilitaCreditCheckModal: boolean;
    impersonatedAgent?: VirtualAgentBackOffice;
    selfCertification?: SelfCertificationData;
    incident: Incident;
    ivassAnswers: QuestionIvassState[];
    insuranceDummyProductId: string;
    linkedCommodity?: {
        enabled: boolean;
        cartId?: string;
        quoteId?: string;
    };
    quoteId?: string;
    // #endregion
    sendCommunications?: boolean;
    isInstantAcceptance?: boolean;
    blockDataEditingOnDoiCheckDoiResend?: boolean;
    skpAutofillIdList?: string[];
    provider?: string;
    transferModel?: TransferModelResponse;

    constructor(flowType = FlowType.SwitchIn) {
        this.version = 2;
        this.flowType = flowType;
        this.firma = new Firma();
        this.mp3Info = new Mp3Info();
        this.quoteStateModel = new QuoteStateModel();
        this.impersonatedAgent = null;
        this.creditCheckStatus = new CreditCheckStatus();
    }
}

export interface ContactV2 {
    mainAddress?: StateAddress | false; // indirizzo residenza / sede legale
    // stessoIndirizzoResidenza => mappare con selector mainAddress  vs deliveryAddress
    // stessoIndirizzoComunicazioni => mappare con selector communicationAddress  vs deliveryAddress
    // stessoIndirizzoSpedizione => mappare con selector deliveryAddress(comm) vs deliveryAddress(extra comm)
    // isResidenceAddressChanged // NON SERVE PERCHE' NON USATO
    domicileAddress?: StateAddress; // indirizzo di domicilio
    privacyTrattDatiPers: DomandePrivacy;
    phoneContactStatus: 'Updated' | 'Confirmed'; // termination.temContact[0]
    mailContactStatus: 'Updated' | 'Confirmed'; // termination.temContact[1]
    /**
     * @description indica se l'email è stata confermata dall'utente
     */
    emailConfirmed: boolean;
    /**
     * @description indica se il cliente è registrato sul sistema d365 (editabilità email/cell per cambio prodotto)
     */
    isRegistered?: boolean;

    /**
     * @description CustomerCode / AccountNumber utilizzato in caso di variazione dello stesso rispetto al customer (AGGREGA / DISAGGREGA)
     */
    customerCode?: string;
}

/*
  _                            _              _       _ 
 (_)_ __ ___  _ __   ___  _ __| |_ __ _ _ __ | |_    | |
 | | '_ ` _ \| '_ \ / _ \| '__| __/ _` | '_ \| __|   | |
 | | | | | | | |_) | (_) | |  | || (_| | | | | |_    |_|
 |_|_| |_| |_| .__/ \___/|_|   \__\__,_|_| |_|\__|   (_)
             |_|                                      

 QUALSIASI, ripeto QUALSIASI aggiunta/modifica di nuove proprietà/oggetto 
 dello state OrdeEntryState deve essere concordata con: felice.lombardi@avanade.com / m.ricupero@avanade.com
 DIVERSAMENTE, VERRANNO RIGETTATE TUTTE LE PR CONTENENTI QUESTO FILE. 

*/
export interface Product {
    /**
     * Identificativo numerico di ordine nell'array dei prodotti
     */
    idx: number;
    /**
     * @description Prodotti esistenti per Cambio Prodotto, Voltura, etc...
     */
    assetId?: string;
    /**
     * @description Indica se questo prodotto polizza punta ad una commodity che risulta già attiva oppure in attivazione
     * (serve ad APIM per valorizzare il campo "egl_iscustomer_commodity" per valorizzare la checkbox sul plico che indica se il cliente è già cliente Plenitude oppure no)
     */
    isCommodityActive: boolean;
    startDate?: Date;
    isAssetActive?: boolean;
    productId: string;
    codeOrSku: string;
    name: string;
    productType: AptProductType;
    family?: AptProductFamily;
    subProductType?: SubProductType;
    familyOps?: string;
    podPdr?: string;
    pdf?: string;
    /**
     * @description Dati del distributore della commodity
     */
    vendor?: Vendor;
    /**
     * @description isCombinedSaleInsurance
     */
    isCombinedSale: boolean;
    // uniqueProductCode: string; // forse da rimuovere perchè = codeOrSku
    /**
     * @description convogliare su questo campo tutte le logiche discriminazione power/has
     */
    powerOrGas: AptCommodityType;
    paymentInfo: PaymentInfo;
    addressType: AptAddressType;
    lineItemId: string;
    lineItemStatus: AptLineStatus;
    technicalDetails: TechnicalDetails;
    /**
     * @description indirizzo di spezione per extra / indirizzo di fornitura per comm
     */
    deliveryAddress: StateAddress;
    /**
     * @description indirizzo di comunicazione e fatturazione
     */
    communicationAddress: StateAddress;

    /**
     * @description consociata
     */
    subsidiary: Subsidiary;

    appointment: MeterAppointment;
    /**
     * @description costi attivaz, swin, cess
     */
    prices: ProductPriceInfo;
    partNumber?: string | SalesProcessPartNumber;
    /**
     * @description utile in CP prefeabbricato da createState salesforce
     */
    assetIntegrationId: string;
    configurations: ProductConfigurations;
    discountsPromo: string[];
    discountsPromoPrices: DiscountsPromoPrices[];
    brickPriceList: number;
    brickList: BrickType[];
    privacyRequired: boolean;
    /**
     * @description mainLine.egl_supply_code
     */
    supplyCode: string;
    isWinBack: boolean;
    businessDetails: BusinessDetails;
    isMortisCausa: boolean;
    sourceCustomer?: {
        mastership: ExistingMastershipType;
        billingPreferenceCode?: string;
        assetId?: string;
    };
    originalStartDate?: Date;
    /**
     * @description termDate / maintenance date
     */
    effectiveDate?: Date;

    /**
     * @description Scorporata da effectiveDate e immediateEffect per non svrascrivere i carrelli misti con manutenzione
     */
    actDate?: Date;
    activationImmediateEffect?: boolean;

    /**
     * @description Decorrenza anticipata isImmediateEffect
     */
    immediateEffect?: boolean;
    // attributes: ProductAttributes;
    plannedSwitchOutDate?: Date;
    isFilled?: boolean;
    /**
     * @description isExpired
     */
    isAllowedDeferredSale?: boolean;
    /**
     * @description da usare in un selettore per calcolare isAtNew
     */
    productStatus: AptProductStatus;
    isVisible: boolean;
    /**
     * @description Telelettura sì o no nei processi di voltura commodity GAS
     */
    meterRemoteRead: boolean;
    ese: Ese;
    booking: ExtracommodityBooking;
    /**
     * @description Indica se il prodotto è indicato per i clienti vulnerabili (Delibera 100/102)
     */
    vulnerable: boolean;
    summaryPrice: string;
    summaryPriceDiscounted: string;
    checkAutofill: string;
    isFirstIban?: boolean;
    isAutogenerate?: boolean;
    customerHabits: string;
    supplyAssetId: string;
    shippingMethod: string;
}

export interface BrickType {
    brickName: string;
    brickPrice: number;
    quantity: number;
    sequence: number;
    isDiscountItem: boolean;
    promoPrice: number;
    discountType: string;
    isAutogenerate?: boolean;
}

export interface Subsidiary {
    isSubsidiaryAddress: boolean;
    agencyBranch: AgencyBranchForSubsidiary;
}
export interface Vendor {
    // vendorCode?: string; // Da verificare
    /**
     * @description Nome Distributore - egl_commodity_distributor__c - NomeDistributore
     */
    name: string;
    /**
     * @description vendorId - PIVA Distributore - PivaDistributore
     */
    taxCode: string;
    /**
     * @description Tipo Distributore Nazionale/Estero (IT) - TipoDistributore
     */
    type: string;
    /**
     * @description Sorgente (SIEBEL) - Sorgente
     */
    mastership: string;
    /**
     * @description Mercato (RETAIL) - Mercato
     */
    market: string;
}

export interface BusinessDetails {
    /**
     * @description Codice Ateco - egl_Customer_ATECO_Code__c
     */
    ateco?: string;
    atecoDescription?: string;
    validated?: boolean;
    /**
     * @description Attività merceologica - egl_commercial_branch__c
     */
    activity?: string;
    /**
     * @description Settore merceologico - egl_Commodity_Sector__c
     */
    sector?: string;
    numberOfEmployees?: string;
    revenue?: string;
    /**
     * @description Classificazione 64/09 - eglAssetLineItems - egl_classification_64_09__c
     */
    class6409?: AtecoClassification6409;
}
export interface ProductConfigurations {
    /**
     * @description egl_green_option__c
     */
    greenOption?: boolean;
    /**
     * @description egl_Invoice_Shipping_Method__c
     */
    invoiceShippingMethod?: AptBillType;

    /**
     * @description egl_Hours_Bundle__c
     */
    hoursBundle?: HoursBundle;

    /**
     * @description N da mappare come null
     */
    partnership?: PartnerShipInfo;
    /**
     * @description prima casa seconda casa
     */
    homeTaxRelief?: CommodityEstateCardinality;
    propertyOwnership?: TitolaritaImmobile;
    propertyType?: PropertyTypes;
    /**
     * @description Pertinenza o casa
     */
    destinationUse?: DestinationUse;
    /**
     * @description egl_selling_order_number__c
     */
    relatedOrderNumber?: string;
    /**
     * @description egl_supply_use__c
     * Distinto sempre su POWER da egl_power_typesofusage__c
     * Domestico Residente, Domestico non residente, Colonnine di ricarica elettrica, Illuminazione pubblica, Altri usi
     */
    supplyUse?: SupplyUse;
    /**
     * @description: egl_using_registration_tool__c
     * SI/NO
     */
    registrationTool?: string;
    /**
     * @description: egl_gas_potential_consumption__c
     */
    potentialConsumption: string;
    /**
     * @description: egl_Floor__c - Piano abitativo
     */
    floor?: string;
    /**
     * @description egl_Privacy_Zurich_01__c
     */
    allowDigitalCommunications: boolean;
    /**
     * @description Numero rate pagamento
     */
    installmentDuration: number;
    quantity: number;
    /**
     * @description egl_addebitoinbolletta__c
     */
    billCharge: boolean;
    vulnerabilityOver75: boolean;
    vulnerabilitySocialBonus: boolean;
    vulnerabilitySaeMapre: boolean;
    vulnerabilityDisabled: boolean;
    centralizedSystem: boolean;
    hasWarranty: boolean;
    age: string;
    heatingSystemType: string;
    typeWaterHeater: string;
    heatingSystemFuel: string;
    brand: string;
    productClass?: NetaProductClass;
    creditAssignment: boolean;
    withHoldingTax: boolean;
    installmentFlag: boolean;
    saleabilityZip: string;
    productTaxDeduction?: ProductTaxDeduction;

    /**
     * @description: Data installazione caldaia
     */
    installmentDate: string;

    priceList?: string;
    /**
     * @description Campagna credito al consumo
     */
    creditCampaign: string;

    /**
     * @description Utilizzo merce presente in magazzino
     */
    usedGoodsInStock: string;

    /**
     * @description Ritiro RAEE
     */
    raee: string;

    /**
     * @description PAV cessione del credito
     */
    creditAssignmentAttr: string;

    /**
     * @description PAV Offerta correlata
     */
    relatedOffer: string;

    /**
     * @description PAV Adotta un pannello per prodotti commodity
     */
    adoptPanel: string;
}

export interface PaymentInfo {
    /**
     * @description IBAN selezionato attraverso payment-tools pre-esistenti. In caso di censimento nuovo IBAN (non in payment-tools) i dati raccolti sono in state.datiPagamento.formBonifico. In caso di REVOCA DOMICILIAZIONE = null
     */
    existingPaymentTool?: string;
    numeroDomiciliazione?: string;

    paymentInstrument?: AptPaymentInstrument;
    paymentFrequency?: AptPaymentFrequency;
    paymentType: AptPaymentType;
    paymentDeposit?: number;
    paymentTool?: PaymentTool;
}

export interface PaymentTool {
    /**
     * @description valorizzato in caso di payment tool esistente
     */
    id: string;
    oldId: string;
    iban: string;
    creditCardNumber: string;
    creditCardExpirationDate: string;
    /**
     * @description teoriacamente non serve perchè ricavabile da billingPrefernce
     */
    billingPreferenceCode: string;
    holder: CompanyIdentity & PersonalIdentity;
    sepaSubscriber: PersonalIdentity;
    bankAccountOwner: IntestarioConto; // solo SUP
    revocationReason: MotivoRevoca;
    membership: string;
    /**
     * @description Nel flusso di domiciliazione modifica, flag per disattivare vecchia carta di credito
     */
    deactivateOldPaymentTool: boolean;
    caseID?: string; //gestisce le domiciliazioni aperte dal CASE domiciliation livello 3 di D365
}

//export interface ProductAttributes {}

export type TechnicalDetails = Omit<
    PowerTechnicalDetails & GasTechnicalDetails & ExtracommodityTechnicalDetails & PanelTechnicalDetails,
    'typeOfUsage'
> & {
    typeOfUsage?: TypeOfUse;
};

export interface PanelTechnicalDetails {
    geographicalArea?: string;
    power?: number;
    size?: string;
}

export interface PowerTechnicalDetails extends CommodityTechnicalDetails, PowerTechnicalParams {
    needed?: PowerTechnicalParams & CommonCommodityTechnicalDetails; // Tutti campi _required__c
    pwrDeclaredConsumption?: number;
    pwrTranscodedConsumption?: number;
    /**
     * @description GAS: concatenazione 'Cottura', 'Acqua Calda', 'Riscaldamento' | POWER: 'Uso Domestico' - egl_typeofusage
     */
    typeOfUsage?: PowerTypeOfUse;
    identificativiRichiesta?: {
        pivaDistributore?: string;
    };
}

export interface PowerTechnicalParams {
    /**
     * @description Potenza Impegnata per la luce - egl_engaged_power - (needed) egl_engaged_power_required__c, egl_Required_Power__c
     */
    pwrInstantaneousPower?: number;
    /**
     * @description Tensione -  egl_voltage__c - (needed)  egl_voltage_required__c
     */
    pwrVoltage?: AptPowerVoltage;
    /**
     * @description Potenza disponibile - egl_Available_Power__c , egl_Available_Power_required__c
     */
    pwrAvailablePower?: number;
    /**
     * @description N. Fasi - egl_num_of_phases_available__c - (needed)  egl_num_of_phases_required__c
     */
    pwrPowerPhases?: PowerPhase;
}

export interface GasTechnicalDetails extends CommodityTechnicalDetails {
    /**
     * @description tipologiaPdr - egl_pdr_type
     */
    gasMeterScope?: PdrType;
    /**
     * @description cifreMisuratore - egl_digits_meter
     */
    gasMeterDigits?: number;
    /**
     * @description classeMisuratore
     */
    gasMeterClass?: GasMeterClass;
    /**
     * @description gruppoMisuratoreIntegrato - egl_meter_integrated
     */
    gasMeterEmbeddedCluster?: boolean;
    /**
     * @description coefficienteCorrettivoC - egl_c_coefficient_corrective
     */
    gasMeterC2AdjustmentFactor?: number;
    /**
     * @description matricola correttore - egl_serial_number_corrector
     */
    gasMeterAdjustmentSerialNumber?: string;
    /**
     * @description cifreCorrettore - egl_digits_corrective
     */
    gasMeterAdjustmentDigit?: number;
    /**
     * @description letturaCorrettore - egl_corrector_reading
     */
    gasMeterAdjustmentNumber?: number;
    /**
     * @description Potenziale
     */
    gasPotential?: number;
    gasPotentialFromCheckPdr?: boolean;
    /**
     * @description GAS: concatenazione 'Cottura', 'Acqua Calda', 'Riscaldamento' | POWER: 'Uso Domestico' - egl_typeofusage
     */
    typeOfUsage?: GasTypeOfUse;

    /**
     * @description Il Citygate rappresenta il punto di ingresso del gas naturale nella rete di distribuzione urbana o metropolitana
     */
    gasCityGate?: string;

    /**
     * @description Classe di prelievo la cui transcodificata segue: - 1 = 7 gg_consumo settimanali
- 2 = 6 gg_consumo settimanali (escluse domenica e fest naz)
- 3 = 5 gg_consumo settimanali (esclusi sabato e domenica e fest naz

     */
    gasWithdrawalClass?: ClasseDiPrelievo;

    /**
     *
     *      * @description Non deve contenere decimali
     */
    gasProgressiveAnnualVolumes?: number;

    /**
     * @description Indica il codice Istat del Comune in cui sarà effettuato lo Switch-in.
     */
    gasIstatCommon?: string;

    /**
     * @description Proprietario dell'infrastruttura che porta energia elettrica o gas a ciascun POD o PDR e verrà censita la P.IVA del Distributore.
     */
    gasDistributorTaxCode?: string;
}

export interface CommonCommodityTechnicalDetails {
    /**
     * @description letturaMisuratore - egl_meter_reading
     */
    meterCounter?: number;
}

export interface CommodityTechnicalDetails extends CommonCommodityTechnicalDetails {
    needed?: CommonCommodityTechnicalDetails;
    /**
     * @description GAS: concatenazione 'Cottura', 'Acqua Calda', 'Riscaldamento' | POWER: 'Uso Domestico' - egl_typeofusage
     */
    typeOfUsage?: TypeOfUse;
    /**
     * @description consumo gas oppure 'declared' per power
     */
    consumption?: number;
    /**
     * @description dataPresuntoSwitchOut
     */
    switchOutDate?: Date;
    /**
     * @description meterNumber - egl_serial_number
     */
    meterSerialNumber?: string;
    /**
     * @description Codice distributore
     */
    vendorCode?: string;
    /**
     * @description codice Remi -  egl_citygate__c
     */
    cityGate?: string;
}

export interface ExtracommodityTechnicalDetails {
    characteristics: { Id: string; Name: string }[];
}

interface DeviceAppointment {
    available: boolean;
    notes: string;
}

export interface MeterAppointment extends DeviceAppointment {
    location?: string;
}

export interface OrderAppointment extends Identity, Partial<Contact> {
    timeslot: string;
    presence?: boolean;
    interphone?: DeviceAppointment;
}

export interface PersonalIdentity extends Identity {
    fiscalCode: string;
}

export interface CompanyIdentity extends PersonalIdentity {
    vatCode: string;
    companyName: string;
}

export interface Identity {
    firstName: string;
    lastName: string;
    fullName?: string;
}

export interface Contact extends PhoneContact, MailContact {}

export interface PhoneContact {
    prefix: string;
    phone: string;
}

export interface MailContact {
    email: string;
}

export interface StateAddress extends Address {
    d365Id?: string;
}

export interface Address {
    toponym: string;
    street: string;
    civic: number;
    civicSuffix?: string;
    /**
     * @description Frazione o municipio - Non presente nel DataModel, necessario per disambiguare alcuni risultati di Egon
     */
    district?: string;
    municipality: string;
    shortProvince: string;
    province: string;
    region?: string;
    cap: string;
    country: string;
    fullAddress: string;

    iso3166Alpha3: string;
    istatCodeProv: string;
    istatCodeMunicipality: string;

    /**
     * @description: egl_Floor__c
     * Piano abitativo
     */
    floor?: string;

    civicEgonCode?: number;
    streetEgonCode?: number;
    certified: boolean;
    isModified?: boolean; // termination.isInvoiceDeliveryAddressUpdated
    /**
     * @description Nel caso di indirizzi sporchi salvati lato be, permette di modificare l'indirizzo nella pagina degli indirizzi, vedi #188962 per ulteriori informazioni
     */
    isLegitWrongAddress?: boolean;

    /**
     * @description Nel caso di indirizzo di Domicilio
     */
    isDwellingTypeUsualHome?: boolean; //abitazione abituale
    isDwellingTypePropertyHome?: boolean; //abitazione di propietà
}

export interface ProductPrice {
    /**
     * @description: Costi Distributore sdr
     */
    sdr?: number;
    /**
     * @description: Costi Distributore rca - (rca => costi distributore per accertamenti)
     */
    rca?: number;
    /**
     * @description: Costi EGL - OPS Quota Fissa Venditore + OPS Net@ / Costi amministrativi da lineItem
     */
    ops?: number;
    /**
     * @description: Imponibile soggetta ad Iva (Quota Potenza + Quota Fissa Distributore)
     */
    isi?: number;
}

export interface ProductPriceInfo extends ProductPrice {
    skip?: boolean;
    /**
     * @description Contattare cliente - egl_contact_customer__c
     */
    notify?: boolean;
    /**
     * @description Preventivo Predeterminato - egl_predeterminate_estimate__c
     */
    predetermined?: boolean;
    informationCosts?: boolean;
    netPrice?: string;
    noVatPrice?: string;
}

export class DatiAnagraficiMBV2 {
    isAtoka: boolean;
    piva: string;
    cf: string;
    companyName: string;
    legalForm: string;
    nameLegal: string;
    surnameLegal: string;
    cfLegal: string;
    representativeType: AptContactRole = AptContactRole.RappresentanteLegale;
}
export interface ExtracommodityBooking {
    bookingId: string;
    startTime: Date;
    endTime: Date;
    bookableResourceId: string;
}

export interface ProductTaxDeduction {
    taxDeductionId?: string;
    taxDeductionDescription?: string;
}

export interface DiscountsPromoPrices {
    promoName: string;
    promoPrice: string;
    discountType: string;
}

export interface FornitureXCommAttive {
    powerOrGas: string;
    podPdr?: string;
    pdf?: string;
    indirizzoCompleto: string;
    toponimo: string;
    provincia: string;
    cap: string;
    civico: string;
    via: string;
    citta: string;
    isWinBack: boolean;
    isResidente: boolean;
    dataPresuntoSwitchOut?: string;
    stato: string;
    classificazione4609?: string;
    atecoCode?: string;
    datiInizio?: string;
    assetIntegrationId?: string;
    supplyCode?: string;
    codiceIstatComune: string;
    billingAccountId?: string;
    dOMMConsumoPrev?: string;
    dOMMCorrettoreGasFlg?: string;
    dOMMPotenzialita?: string;
    dOMMTensione?: string;
    dOMMUtilizzoCompostoDesc?: string;
    morositaSDR?: string;
    pertinenze: string;
    potenzaImpegnata?: string;
    dOMMPotenzaDisponibile?: string;
    productName?: string;
    productType: string;
    titolareFornitura?: string;
    operatingStatus?: string;
}
