import { Injectable } from '@angular/core';
import { Convention, LocalStorageConventionsWrapper } from '../../../modules/common/partnership/models/conventions';
import {
    LocalStorageTaxDeductionsWrapper,
    TaxDeduction,
} from '../../../modules/common/partnership/models/taxDeductions';
import { QuestionIvassState } from '../../../modules/switch-in/products/models/ivass-question';
import { FlowType } from '../../../store/models/flow-type';
import { D365CustomerSegment } from '../../enums/d365/d365-customer-segment';

@Injectable({ providedIn: 'root' })
export class LocalStorageGenericService {
    public get localCart(): string {
        return localStorage.getItem(LOCAL_CART);
    }

    public set manageSegments(v: Partial<ISegmentsForPiva>) {
        if (v) {
            v = { ...this.manageSegments, ...v };
            localStorage.setItem(MANAGE_SEGMENTS, JSON.stringify(v));
        } else {
            localStorage.removeItem(MANAGE_SEGMENTS);
        }
    }

    public get manageSegments(): ISegmentsForPiva {
        return JSON.parse(localStorage.getItem(MANAGE_SEGMENTS)) as ISegmentsForPiva;
    }

    public set catalogFilterFlowType(v: FlowType) {
        if (v) {
            localStorage.setItem(FILTER_FLOWTYPE, v);
        } else {
            localStorage.removeItem(FILTER_FLOWTYPE);
        }
    }

    public get catalogFilterFlowType() {
        const value = localStorage.getItem(FILTER_FLOWTYPE);
        if (value) {
            return value as FlowType;
        }
        return null;
    }

    //#region answerIVASS
    public get answerIVASS(): IAnswerIVASS {
        return JSON.parse(localStorage.getItem(ANSWER_IVASS)) as IAnswerIVASS;
    }

    public set answerIVASS(v: IAnswerIVASS) {
        if (v) {
            localStorage.setItem(ANSWER_IVASS, JSON.stringify(v));
        } else {
            localStorage.removeItem(ANSWER_IVASS);
        }
    }
    //#endregion

    //#region capCatalogFilter
    public get catalogZipFilter(): ICapCatalogFilter {
        return JSON.parse(localStorage.getItem(CATALOG_ZIP_FILTER)) as ICapCatalogFilter;
    }

    public set catalogZipFilter(v: ICapCatalogFilter) {
        if (v) {
            localStorage.setItem(CATALOG_ZIP_FILTER, JSON.stringify(v));
        } else {
            localStorage.removeItem(CATALOG_ZIP_FILTER);
        }
    }
    //#endregion

    //#region fastwebShownContracts
    public get fastwebShownContracts(): number {
        const value = localStorage.getItem(SHOWN_FASTWEB_CONTRACTS);
        if (value) {
            return +value;
        }
        return null;
    }

    public set fastwebShownContracts(v: number) {
        if (v) {
            localStorage.setItem(SHOWN_FASTWEB_CONTRACTS, v.toString());
        } else {
            localStorage.removeItem(SHOWN_FASTWEB_CONTRACTS);
        }
    }
    //#endregion

    //#region applicableConventions
    public get applicableConventions(): Convention[] {
        const convData = JSON.parse(
            localStorage.getItem(APPLICABLE_CONVENTIONS) || JSON.stringify(new LocalStorageConventionsWrapper()),
        ) as LocalStorageConventionsWrapper;
        if (!convData.cartId || convData.cartId === this.localCart) {
            return convData.conventions;
        }
        return [];
    }

    public set applicableConventions(conventions: Convention[]) {
        if (conventions) {
            const wrapper: LocalStorageConventionsWrapper = {
                cartId: this.localCart,
                conventions,
            };
            localStorage.setItem(APPLICABLE_CONVENTIONS, JSON.stringify(wrapper));
        } else {
            localStorage.removeItem(APPLICABLE_CONVENTIONS);
        }
    }
    //#endregion

    //#region applicableTaxDeductions
    public get applicableTaxDeductions(): TaxDeduction[] {
        const convData = JSON.parse(
            localStorage.getItem(APPLICABLE_TAX_DEDUCTION) || JSON.stringify(new LocalStorageTaxDeductionsWrapper()),
        ) as LocalStorageTaxDeductionsWrapper;
        if (!convData.cartId || convData.cartId === this.localCart) {
            return convData.taxDeductions;
        }
        return [];
    }

    public set applicableTaxDeductions(taxDeductions: TaxDeduction[]) {
        if (taxDeductions) {
            const wrapper: LocalStorageTaxDeductionsWrapper = {
                cartId: this.localCart,
                taxDeductions,
            };
            localStorage.setItem(APPLICABLE_TAX_DEDUCTION, JSON.stringify(wrapper));
        } else {
            localStorage.removeItem(APPLICABLE_TAX_DEDUCTION);
        }
    }
    //#endregion

    //#region aCart
    public set latestACartLoaded(id: string) {
        if (id) {
            localStorage.setItem(ACART_ID, id);
        } else {
            localStorage.removeItem(ACART_ID);
        }
    }

    public get latestACartLoaded() {
        return localStorage.getItem(ACART_ID);
    }
    //#endregion

    //#region cap della modale di vendibilità (ad oggi solo su manutenzione)
    public set productsZip(item: ZipProduct[]) {
        if (item) {
            localStorage.setItem(PRODUCT_ZIP, JSON.stringify(item));
        } else {
            localStorage.removeItem(PRODUCT_ZIP);
        }
    }

    public get productsZip(): ZipProduct[] {
        return JSON.parse(localStorage.getItem(PRODUCT_ZIP));
    }
    //#endregion cap della modale di vendibilità (ad oggi solo su manutenzione)

    //#region ripristina i filtri bo
    public set restoreBoFilters(restore: boolean) {
        localStorage.setItem(RESTORE_BO, JSON.stringify(restore));
    }

    public get restoreBoFilters(): boolean {
        return JSON.parse(localStorage.getItem(RESTORE_BO)) || false;
    }
    //#endregion
}

const LOCAL_CART = 'local-cart';
const CATALOG_ZIP_FILTER = 'catalog.zipfilter';
const ANSWER_IVASS = 'catalog.answerIVASS';
const FILTER_FLOWTYPE = 'catalog.filterFlowType';
const MANAGE_SEGMENTS = 'catalog.manageSegments';
const SHOWN_FASTWEB_CONTRACTS = 'partnership.fastweb.shownContracts';
const APPLICABLE_CONVENTIONS = 'catalog.applicableConventions';
const APPLICABLE_TAX_DEDUCTION = 'catalog.applicableTaxDeductions';
const ACART_ID = 'cart.latest-acart';
const PRODUCT_ZIP = 'product.zip';
const RESTORE_BO = 'bo.restorefilters';

export interface ICapCatalogFilter {
    cart: string;
    zip: string;
    prov: string;
    city: string;
    location?: string;
    dismiss?: boolean;
    fullName?: string;
    reg?: string;
}

export interface IAnswerIVASS {
    cart: string;
    dummyProductId: string;
    answers: QuestionIvassState[];
}

export interface ISegmentsForPiva {
    current: D365CustomerSegment;
    initial: D365CustomerSegment;
}

export interface ZipProduct {
    productId: string;
    zip: string;
}

export interface RestoreBoFilters {
    restore: boolean;
}
