import { Action, createReducer, on } from '@ngrx/store';
import { AptAddressType } from '../../common/enums/apttus/apt-address-type';
import { AptCommodityType } from '../../common/enums/apttus/apt-commodity-typeof-sale';
import { AptProductFamily } from '../../common/enums/apttus/apt-product-family';
import { AptProductType } from '../../common/enums/apttus/apt-product-type';
import { productToAptAdressType } from '../../common/functions/misc.functions';
import { getNumberOrNull, productsPriceMapper } from '../../common/functions/remap.functions';
import { orderEntryV1ToV2 } from '../../common/functions/transformation-v1-v2.functions';
import { isCommodityFamily, isOrderEntryStateV2 } from '../../common/functions/verifications.functions';
import { DeepPartial } from '../../common/interfaces/deep-partial';
import * as v2A from '../actions/order-entry-v2.actions';
import {
    Address,
    ContactV2,
    OrderEntryState_v2,
    PaymentInfo,
    PaymentTool,
    Product,
} from '../models/order-entry-state_v2';
import { isActiveVisibleMainProduct } from '../selectors/selector-utility.functions';
import { userStateSelector } from '../selectors/user.selectors';
import { orderEntryLegacyReducers } from './order-entry-v2-legacy.reducer';
import { wrapperUpdateProductByIndex } from './reducer-utility.functions';

export const initialState = new OrderEntryState_v2();

const reducer = createReducer(
    initialState,
    on(v2A.setV2OrderEntryState, (_, { state }) => (isOrderEntryStateV2(state) ? state : orderEntryV1ToV2(state))),
    on(v2A.setV2CartId, (orderStateV2, { id }) => ({
        ...orderStateV2,
        cartId: id,
    })),
    on(v2A.setV2Products, (orderStateV2, { products }) => ({
        ...orderStateV2,
        products: <Product[]>products,
    })),
    on(v2A.setV2ProductLineItemId, (state, { productIdx, lineItemId }) =>
        wrapperUpdateProductByIndex<{ lineItemId: string }>(
            ({ payload }) => ({
                lineItemId: payload.lineItemId,
            }),
            {
                overwrite: false,
                productIdx: getNumberOrNull(productIdx),
            },
        )(state, { lineItemId }),
    ),
    on(v2A.setV2Ese, (state, { ese, lineItemId }) => {
        let products = [...state.products];
        let productToUpdate = products.find((product) => product.lineItemId === lineItemId);
        if (productToUpdate) {
            productToUpdate.ese = ese;
        }
        return {
            ...state,
            products,
        };
    }),
    on(v2A.setV2ProductFilled, (state, { productIdx, isFilled }) =>
        wrapperUpdateProductByIndex<{ isFilled: boolean }>(
            ({ payload }) => ({
                isFilled: payload.isFilled,
            }),
            {
                overwrite: false,
                productIdx: getNumberOrNull(productIdx),
            },
        )(state, { isFilled }),
    ),
    on(v2A.setV2ProductByIdx, (state, { productIdx, product }) =>
        wrapperUpdateProductByIndex<{ product: DeepPartial<Product> }>(
            ({ payload }) => ({
                ...payload.product,
            }),
            {
                overwrite: false,
                productIdx: getNumberOrNull(productIdx),
            },
        )(state, { product }),
    ),
    on(v2A.setV2ProductsWinBack, (state, { winBackProducts }) => {
        // Il Winback esiste solo se a carrello c'è una sola commodity gas / luce / dual
        // in caso di multifornitura non esiste la winback. Anzi, andrebbe bloccata in order entry.
        // Sentire Canu per chiarimenti.

        const products = state.products?.map((product) => {
            if (isActiveVisibleMainProduct(product) && isCommodityFamily(product.family)) {
                const linkedWinBackProduct = winBackProducts.find(
                    (winProduct) => winProduct.powerOrGas === product.powerOrGas,
                );
                if (!!linkedWinBackProduct) {
                    return {
                        ...product,
                        ...linkedWinBackProduct,
                        technicalDetails: {
                            ...product?.technicalDetails,
                            switchOutDate: linkedWinBackProduct?.technicalDetails?.switchOutDate,
                        },
                        isWinBack: true,
                    };
                } else {
                    return {
                        ...product,
                        podPdr: null,
                        isWinBack: null,
                        deliveryAddress: null,
                        assetIntegrationId: null,
                        technicalDetails: {
                            ...product?.technicalDetails,
                            switchOutDate: null,
                        },
                    };
                }
            } else {
                return product;
            }
        });

        return {
            ...state,
            products,
        };
    }),
    on(v2A.setV2ProductInsurance, (state, { insuranceProducts }) => {
        const insurancePowerGasProductsMap: {
            [type in AptCommodityType]: (typeof insuranceProducts)[0];
        } = insuranceProducts.reduce(
            (aggr, { powerOrGas, deliveryAddress, podPdr, isCommodityActive }) => ({
                ...aggr,
                [powerOrGas]: {
                    deliveryAddress,
                    podPdr,
                    isCommodityActive,
                },
            }),
            {
                //Lista di asset Power selezionati (da collegare alle assicurazioni)
                [AptCommodityType.Power]: null,
                //Lista di asset Gas selezionati (da collegare alle assicurazioni)
                [AptCommodityType.Gas]: null,
            },
        );

        return {
            ...state,
            products: (state?.products || []).map((product) => {
                const targetAssetCommodity = product?.powerOrGas
                    ? insurancePowerGasProductsMap[product?.powerOrGas]
                    : insurancePowerGasProductsMap.GAS || insurancePowerGasProductsMap.POWER;
                return isActiveVisibleMainProduct(product) && product.family === AptProductFamily.Assicurazione
                    ? {
                          ...product,
                          ...(targetAssetCommodity || {}),
                      }
                    : product;
            }),
        };
    }),
    on(v2A.setV2CustomerCode, (orderStateV2, { customerCode }) => ({
        ...orderStateV2,
        contact: {
            ...(orderStateV2.contact || ({} as ContactV2)),
            customerCode,
        },
    })),
    on(v2A.setV2MainAddress, (orderStateV2, { address }) => ({
        ...orderStateV2,
        contact: {
            ...(orderStateV2.contact || ({} as ContactV2)),
            mainAddress: address || ({} as Address),
        },
    })),
    on(v2A.setV2SetEmailConfirmed, (orderStateV2, { emailConfirmed }) => ({
        ...orderStateV2,
        contact: {
            ...(orderStateV2.contact || ({} as ContactV2)),
            emailConfirmed,
        },
    })),
    on(v2A.setV2CustomerIsRegistered, (orderStateV2, { isRegistered }) => ({
        ...orderStateV2,
        contact: {
            ...(orderStateV2.contact || ({} as ContactV2)),
            isRegistered,
        },
    })),
    on(
        v2A.setV2InvoiceShippingMethod,
        wrapperUpdateProductByIndex(
            ({ payload: { method } }) => ({
                configurations: {
                    invoiceShippingMethod: method,
                },
            }),
            { overwrite: false },
        ),
    ),
    on(
        v2A.setV2SkipPrices,
        wrapperUpdateProductByIndex(
            ({ payload: { skipPrices } }) => ({
                prices: { skip: skipPrices },
            }),
            { overwrite: false, productIdx: 'ALL' },
        ),
    ),
    on(v2A.setV2Prices, (state, { prices }) => ({
        ...state,
        products: productsPriceMapper(state.products, prices),
    })),
    on(v2A.setV2Vendor, (state, { vendor }) => ({
        ...state,
        products: state.products.map((product) => ({
            ...product,
            vendor: Object.assign({}, product.vendor || {}, vendor),
        })),
    })),
    on(
        v2A.setV2EffectiveDate,
        wrapperUpdateProductByIndex(
            ({ payload: { effectiveDate, immediateEffect } }) => ({
                effectiveDate,
                immediateEffect,
            }),
            { overwrite: false, productIdx: 'ALL' },
        ),
    ),
    on(v2A.setV2EffectiveDateComplex, (state, { products, immediateEffect }) => ({
        ...state,
        products: state.products.map((p, index) =>
            products.includes(index) ? { ...p, immediateEffect: immediateEffect } : { ...p },
        ),
    })),
    on(v2A.setV2EffectiveDatePannello, (state, { effectiveDate, immediateEffect }) => ({
        ...state,
        products: state.products.map((p) =>
            p.productType == AptProductType.Pannello
                ? { ...p, immediateEffect: immediateEffect, effectiveDate: effectiveDate }
                : { ...p },
        ),
    })),
    on(
        v2A.setV2ActivationDate,
        wrapperUpdateProductByIndex(
            ({ payload: { actDate, activationImmediateEffect } }) => ({
                actDate,
                activationImmediateEffect,
            }),
            { overwrite: false, productIdx: 'ALL' },
        ),
    ),
    on(
        v2A.setV2PartNumber,
        wrapperUpdateProductByIndex(
            ({ payload: { partNumber } }) => ({
                partNumber,
            }),
            { overwrite: false, productIdx: 'ALL' },
        ),
    ),
    on(
        v2A.setV2Pdf,
        wrapperUpdateProductByIndex(
            ({ payload: { pdf } }) => ({
                pdf,
            }),
            { overwrite: false, productIdx: 'ALL' },
        ),
    ),
    on(v2A.setV2Convention, (orderStateV2, { convention }) => ({
        ...orderStateV2,
        convention,
    })),
    on(v2A.setV2TaxDeduction, (orderStateV2, { taxDeduction }) => ({
        ...orderStateV2,
        taxDeduction,
    })),
    on(v2A.setV2CreditAssignment, (orderStateV2, { creditAssignment }) => ({
        ...orderStateV2,
        creditAssignment,
    })),
    on(
        v2A.setV2ProductTaxDeduction,
        wrapperUpdateProductByIndex(
            ({ payload: { productTaxDeduction } }) => ({
                configurations: {
                    productTaxDeduction,
                },
            }),
            { overwrite: false, productIdx: AptCommodityType.Gas },
        ),
    ),
    on(v2A.setV2CommunicationAddress, (state, { address, notFromAddress, productIdx }) => ({
        ...state,
        products: state.products.map((product, index) => ({
            ...product,
            communicationAddress:
                (productIdx && productIdx.includes(index)) || notFromAddress ? address : product.communicationAddress,
        })),
    })),
    on(v2A.setV2DomicileAddress, (orderStateV2, { address }) => ({
        ...orderStateV2,
        contact: {
            ...(orderStateV2.contact || ({} as ContactV2)),
            domicileAddress: address || ({} as Address),
        },
    })),
    on(v2A.setV2SubsidiaryAddress, (state, { subsidiary, productIdx }) =>
        wrapperUpdateProductByIndex<{ subsidiary }>(
            ({ payload: { subsidiary } }) => ({
                subsidiary,
            }),
            {
                overwrite: false,
                productIdx: getNumberOrNull(productIdx),
            },
        )(state, { subsidiary }),
    ),
    on(
        v2A.setV2BusinessDetails,
        wrapperUpdateProductByIndex(
            ({ payload: { businessDetails } }) => ({
                businessDetails,
            }),
            { overwrite: false },
        ),
    ),
    on(
        v2A.setV2TypeOfUsage,
        wrapperUpdateProductByIndex(
            ({ payload: { typeOfUsage } }) => ({
                technicalDetails: { typeOfUsage },
            }),
            { overwrite: false },
        ),
    ),
    on(
        v2A.setV2TechnicalDetailsPower,
        wrapperUpdateProductByIndex(
            ({ payload: { technicalDetails } }) => ({
                technicalDetails,
            }),
            { overwrite: false, productIdx: AptCommodityType.Power },
        ),
    ),
    on(
        v2A.setV2TechnicalDetailsGas,
        wrapperUpdateProductByIndex(
            ({ payload: { technicalDetails } }) => ({
                technicalDetails,
            }),
            { overwrite: false, productIdx: AptCommodityType.Gas },
        ),
    ),
    on(
        v2A.setV2RevocationReason,
        wrapperUpdateProductByIndex(
            ({ payload: { revocationReason } }) => ({
                paymentInfo: {
                    paymentTool: {
                        revocationReason,
                    },
                },
            }),
            { overwrite: false, productIdx: 'ALL' },
        ),
    ),
    on(
        v2A.setV2RemoteReadingStatus,
        wrapperUpdateProductByIndex(
            ({ payload: { status } }) => ({
                meterRemoteRead: status,
            }),
            { overwrite: false, productIdx: AptCommodityType.Gas },
        ),
    ),
    on(v2A.setV2SendCommunications, (state, { sendCommunications }) => ({
        ...state,
        sendCommunications,
    })),
    on(v2A.setV2AnagraficaMB, (state, { anagraficaMb }) => ({
        ...state,
        anagraficaMb,
    })),
    on(
        v2A.setV2SupplyUse,
        wrapperUpdateProductByIndex(
            ({ payload: { supplyUse } }) => ({
                configurations: {
                    supplyUse: supplyUse,
                },
            }),
            { overwrite: false },
        ),
    ),
    on(
        v2A.setV2DeliveryAddress,
        wrapperUpdateProductByIndex(
            ({ payload: { supplyAddress, shippingAddress }, product }) => ({
                ...product,
                ...{
                    [AptAddressType.Fornitura]: {
                        deliveryAddress: supplyAddress,
                        addressType: AptAddressType.Fornitura,
                    },
                    [AptAddressType.Spedizione]: {
                        deliveryAddress: shippingAddress,
                        addressType: AptAddressType.Spedizione,
                    },
                    DEFAULT: { deliveryAddress: null, addressType: null },
                }[productToAptAdressType(product, userStateSelector.projector()?.cartSegment) || 'DEFAULT'],
            }),
            { overwrite: true },
        ),
    ),
    on(v2A.setV2FatturazioneElettronica, (state, { fatturazioneElettronica }) => ({
        ...state,
        fatturazioneElettronica,
    })),
    on(v2A.setV2InformationCosts, (state, { informationCosts }) => ({
        ...state,
        products: state.products.map((product) => ({
            ...product,
            prices: { ...product.prices, informationCosts },
        })),
    })),
    on(
        v2A.setV2TechnicalAppointmentDate,
        wrapperUpdateProductByIndex(
            ({ payload: { booking } }) => ({
                booking,
            }),
            { overwrite: false },
        ),
    ),
    // TODO Lorenzo - Cerca di capire se fattibile in modo più elegante. Per ora è un pezzotto
    on(
        v2A.setV2ShippingAddressOnAllSmartHome,
        wrapperUpdateProductByIndex(
            ({ payload: { deliveryAddress } }) => ({
                deliveryAddress,
                isFilled: true,
                addressType: AptAddressType.Spedizione,
            }),
            { productIdx: 'SMARTHOME' },
        ),
    ),
    on(v2A.setV2IsInstantAcceptance, (orderStateV2, { isInstantAcceptance }) => ({
        ...orderStateV2,
        isInstantAcceptance: isInstantAcceptance,
    })),
    on(v2A.setV2CheckAutofill, (orderStateV2, { checkAutofill, productId }) => ({
        ...orderStateV2,
        products: orderStateV2.products.map((p) => (p.productId === productId ? { ...p, checkAutofill } : { ...p })),
    })),
    on(v2A.setV2NetPrices, (orderStateV2, { resPriceProduct, productId }) => ({
        ...orderStateV2,
        products: orderStateV2.products.map((p) =>
            productId.includes(p.productId)
                ? {
                      ...p,
                      prices: {
                          ...p.prices,
                          netPrice: resPriceProduct
                              .find((rpPricePrd) => rpPricePrd?.productId == p.productId)
                              ?.netPrice?.toString(),
                          noVatPrice: resPriceProduct
                              .find((rpPricePrd) => rpPricePrd?.productId == p.productId)
                              ?.noVatPrice?.toString(),
                      },
                  }
                : { ...p },
        ),
    })),
    on(v2A.setV2AdoptPanel, (orderStateV2, { adoptPanel, productId }) => ({
        ...orderStateV2,
        products: orderStateV2.products.map((p) =>
            p.productId === productId ? { ...p, configurations: { ...p.configurations, adoptPanel } } : { ...p },
        ),
    })),
    on(v2A.setV2CustomerHabits, (orderStateV2, { customerHabits, productId }) => ({
        ...orderStateV2,
        products: orderStateV2.products.map((p) => (p.productId === productId ? { ...p, customerHabits } : { ...p })),
    })),
    on(v2A.setV2DatiMultiPayment, (state, { datiPagamento: p, productIdx }) => ({
        ...state,
        products: [...state.products].map(({ paymentInfo, ...product }, index) => ({
            ...product,
            paymentInfo: productIdx.includes(index)
                ? {
                      ...(paymentInfo || ({} as PaymentInfo)),
                      paymentInstrument: p?.tipoPagamento,
                      existingPaymentTool: p?.existingPaymentTool,
                      paymentTool: {
                          ...(paymentInfo?.paymentTool || ({} as PaymentTool)),
                          id: p?.id,
                          iban: p?.formBonifico?.iban,
                          creditCardNumber: p?.existingPaymentTool,
                          creditCardExpirationDate: p?.creditCardExpirationDate,
                          deactivateOldPaymentTool: p?.deactivateOldPaymentTool,
                          membership: p?.formBonifico?.settoreAppartenenza,
                          bankAccountOwner: p?.formBonifico?.intestatarioConto,
                          holder: {
                              companyName: p?.formBonifico?.titolareConto?.ragioneSociale,
                              vatCode: p?.formBonifico?.titolareConto?.piva,
                              fiscalCode:
                                  p?.formBonifico?.titolareConto?.cf || p?.formBonifico?.titolareConto?.cfAzienda,
                              firstName: p?.formBonifico?.titolareConto?.nome,
                              lastName: p?.formBonifico?.titolareConto?.cognome,
                              fullName: p?.formBonifico?.titolareConto?.fullName,
                          },
                          sepaSubscriber: {
                              firstName: p?.formBonifico?.sottoscrittoreSepa?.nome,
                              lastName: p?.formBonifico?.sottoscrittoreSepa?.cognome,
                              fiscalCode: p?.formBonifico?.sottoscrittoreSepa?.cf,
                          },
                      },
                  }
                : { ...(paymentInfo || ({} as PaymentInfo)) },
        })),
    })),
    on(v2A.setV2FirstIban, (state, { productIdx }) => ({
        ...state,
        products: state.products.map((product, index) => ({
            ...product,
            isFirstIban: productIdx.includes(index),
        })),
    })),
    on(
        v2A.setV2SaleabilityZip,
        wrapperUpdateProductByIndex(
            ({ payload: { zip } }) => ({
                configurations: {
                    saleabilityZip: zip,
                },
            }),
            { overwrite: false, productIdx: 0 },
        ),
    ),
    on(v2A.setV2TransferModel, (orderStateV2, { transferModel }) => ({
        ...orderStateV2,
        transferModel,
    })),
    on(
        v2A.setV2WithdrawalClass,
        wrapperUpdateProductByIndex(
            ({ payload: { gasWithdrawalClass } }) => ({
                technicalDetails: { gasWithdrawalClass },
            }),
            { overwrite: false },
        ),
    ),

    ...orderEntryLegacyReducers,
);

export function orderEntryV2Reducer(state: OrderEntryState_v2 | undefined, action: Action): OrderEntryState_v2 {
    return reducer(state, action);
}
